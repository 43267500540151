import { useCallback, useState } from 'react';

export const useInfiniteLoader = ({ root = null, threshold = 0 } = {}) => {
  const [observer, setObserver] = useState<null | IntersectionObserver>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const measureRef = useCallback(
    (node: Element | null) => {
      if (node) {
        const io = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          {
            root,
            rootMargin: `${window.innerHeight}px`,
            threshold,
          }
        );

        io.observe(node);
        setObserver(io);
      }
    },
    [root, threshold]
  );

  return { measureRef, isIntersecting, observer };
};
