import { DefaultSeo } from 'next-seo';
import { HeadMeta } from '@/components/HeadMeta/HeadMeta';
import { useTranslation } from 'react-i18next';
import { SeoFullData } from '@/api/types/seo.types';
import { makeUrlWithLang } from '@/utils/helpers';
import { CONFIG } from '@/constants/config';

export const Seo = ({ title, description, hostSum }: Partial<SeoFullData>) => {
  const { t, i18n } = useTranslation();
  const finalTitle = title ?? t('common.default_seo_title');
  const finalDescription = description ?? t('common.default_seo_description');

  const finalUrl =
    hostSum?.currentUrl ??
    makeUrlWithLang({
      protocol: CONFIG.protocol || '',
      host: CONFIG.host || '',
      path: '/',
      lang: i18n.language,
    });

  return (
    <>
      <HeadMeta currentUrl={finalUrl} />
      <DefaultSeo
        title={finalTitle}
        description={finalDescription}
        openGraph={{
          title: finalTitle,
          description: finalDescription,
          url: finalUrl,
        }}
        facebook={{
          appId: CONFIG.fbAppId,
        }}
      />
    </>
  );
};
