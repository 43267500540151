import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { callBanner, WindowWithAdriver } from '@/components/Banners/adriver';
import { useBannerStore } from '@/store/useBannerStore/useBannerStore';
import { handleAndLoadBanner } from '@/components/Banners/AdriverBanner.helpers';
import { BannerName } from '@/components/Banners/Banners.types';
import { useAdManagerBannerVisible } from '@/components/Banners/AdManagerBanner.hooks';
import { AdriverBannerId } from '@/components/Banners/Banners.constants';

interface BannerProps {
  containerId: string;
  containerClassName?: string;
  tagName: string;
  sid: string;
  bn: string;
  arCn?: number;
}

export const AdriverBanner: React.FC<BannerProps> = ({
  containerId,
  containerClassName,
  tagName,
  sid,
  bn,
  arCn,
}) => {
  const router = useRouter();
  const tag = tagName || 'div';
  const isAdManagerBannerVisible = useAdManagerBannerVisible();
  const [
    isBlockerDetected,
    setBannerTopSource,
    setBannerBottomSource,
    setIsAdriverTopBannerVisible,
  ] = useBannerStore(state => [
    state.isBlockerDetected,
    state.setBannerTopSource,
    state.setBannerBottomSource,
    state.setIsAdriverTopBannerVisible,
  ]);

  useEffect(() => {
    const bannerRefreshHandler = (currentPath: string) => {
      if (currentPath === '/' && !isBlockerDetected) {
        callBanner(containerId, sid, bn, arCn);
      } else {
        typeof (window as WindowWithAdriver).adriverExtentionLoad !==
          'undefined' &&
          (window as WindowWithAdriver).adriverExtentionLoad?.(
            0,
            null,
            containerId
          );
      }
    };

    if (!isBlockerDetected) {
      callBanner(containerId, sid, bn, arCn);
    } else {
      typeof (window as WindowWithAdriver).adriverExtentionLoad !==
        'undefined' &&
        (window as WindowWithAdriver).adriverExtentionLoad?.(
          0,
          null,
          containerId
        );
    }

    router.events.on('routeChangeComplete', bannerRefreshHandler);

    return () => {
      router.events.off('routeChangeComplete', bannerRefreshHandler);
    };
  }, []);

  useEffect(() => {
    if (containerId === AdriverBannerId[BannerName.Top]) {
      handleAndLoadBanner(
        BannerName.Top,
        setBannerTopSource,
        isAdManagerBannerVisible,
        setIsAdriverTopBannerVisible
      );
    }

    if (containerId === AdriverBannerId[BannerName.Bottom]) {
      handleAndLoadBanner(
        BannerName.Bottom,
        setBannerBottomSource,
        isAdManagerBannerVisible,
        setIsAdriverTopBannerVisible
      );
    }
  }, [router.pathname]);

  return React.createElement(
    tag,
    { id: containerId, className: containerClassName },
    null
  );
};
