import { AdriverBannerId } from '@/components/Banners/Banners.constants';
import {
  BannerSource,
  SetBannerSource,
  SetIsAdriverTopBannerVisible,
} from '@/store/useBannerStore/useBannerStore.types';

export function getBannerElements(bannerType: keyof typeof AdriverBannerId) {
  const banner = document.querySelector(`#${AdriverBannerId[bannerType]}`);

  const bannerIframe = document.querySelector(
    `#${AdriverBannerId[bannerType]} iframe`
  );

  return [banner, bannerIframe];
}

export function handleAndLoadBanner(
  bannerType: keyof typeof AdriverBannerId,
  setBannerSource: SetBannerSource,
  isAdManagerBannerVisible: boolean,
  setIsAdriverTopBannerVisible: SetIsAdriverTopBannerVisible
) {
  if (isAdManagerBannerVisible) {
    setBannerSource(BannerSource.Admanager);
    return;
  }

  const [banner, bannerIframe] = getBannerElements(bannerType);

  setIsAdriverTopBannerVisible(false);

  bannerIframe?.addEventListener('load', () => {
    if (banner?.clientHeight === 0) {
      setBannerSource(BannerSource.Admanager);
    } else {
      setBannerSource(BannerSource.Adriver);
      setIsAdriverTopBannerVisible(true);
    }
  });
}
