import React, { useEffect, useState } from 'react';
import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';
import { ON_VISIT_REPETITION } from '@/components/InfiniteLoader/InfiniteLoader.constants';

interface InfiniteLoaderProps {
  onVisit: () => void;
}

export const InfiniteLoader: React.FC<InfiniteLoaderProps> = ({ onVisit }) => {
  const { measureRef, isIntersecting } = useInfiniteLoader();
  const [isObservableExist, setIsObservableExist] = useState(false);

  useEffect(() => {
    if (isIntersecting) {
      onVisit();
      setIsObservableExist(true);
      setTimeout(() => setIsObservableExist(false), ON_VISIT_REPETITION);
    }
  }, [isIntersecting]);

  if (isObservableExist) {
    return null;
  }

  return <div ref={measureRef} />;
};
