import { useBannerStore } from '@/store/useBannerStore/useBannerStore';
import React, { useMemo } from 'react';
import { BannerSource } from '@/store/useBannerStore/useBannerStore.types';
import { AdriverBanner } from '@/components/Banners/AdriverBanner';
import {
  ADRIVER_SID,
  AdriverBannerId,
} from '@/components/Banners/Banners.constants';
import { BannerName } from '@/components/Banners/Banners.types';
import { AdManagerBanner } from '@/components/Banners/AdManagerBanner';

export function useBanner() {
  const [bannerTopSource, bannerBottomSource] = useBannerStore(state => [
    state.bannerTopSource,
    state.bannerBottomSource,
  ]);

  const topBanner = useMemo(() => {
    return bannerTopSource === BannerSource.Adriver ? (
      <AdriverBanner
        tagName="div"
        containerId={AdriverBannerId[BannerName.Top]}
        containerClassName="lotriver-top-banner"
        sid={ADRIVER_SID}
        bn="2"
        arCn={1}
      />
    ) : (
      <AdManagerBanner bannerName={BannerName.Top} />
    );
  }, [bannerTopSource]);

  const bottomBanner = useMemo(() => {
    return bannerBottomSource === BannerSource.Adriver ? (
      <AdriverBanner
        tagName="p"
        containerId={AdriverBannerId[BannerName.Bottom]}
        containerClassName="lotriver-bottom-banner"
        sid={ADRIVER_SID}
        bn="4"
        arCn={3}
      />
    ) : (
      <AdManagerBanner bannerName={BannerName.Bottom} />
    );
  }, [bannerBottomSource]);

  const scammerBanner = (
    <AdriverBanner
      tagName="div"
      containerId="js-lotriver-banner-scammer"
      sid={ADRIVER_SID}
      bn="9"
      arCn={2}
    />
  );

  const inListBanner = (arCn: number) => {
    return (
      <AdriverBanner
        tagName="div"
        containerId={`js-lotriver-bottom-banner3-${arCn}`}
        containerClassName="lotriver-bottom-banner lotriver-bottom-banner--in-list"
        sid={ADRIVER_SID}
        bn="4"
        arCn={arCn}
      />
    );
  };

  return { topBanner, bottomBanner, scammerBanner, inListBanner };
}
