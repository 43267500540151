export type WindowWithAdriver = Window &
  typeof globalThis & {
    adriver?: unknown;
    adriverExtentionLoad?: (a: number, b: null, cId: string) => void;
    ActiveXObject: any;
  };

const init = async (L: string, container_id: string, ar_cn?: number | null) => {
  // process.env.NODE_ENV === 'production' works also for the Preview on the Vercel
  // use isProduction() helper to check for production mode only
  if (!process.browser || process.env.NODE_ENV !== 'production') return null;

  if (typeof ar_cn === 'undefined' || ar_cn === null) {
    ar_cn = 1;
  }

  let S =
      'setTimeout(function(e){if(!self.CgiHref){document.close();e=parent.document.getElementById("ar_container_"+ar_bnum);e.parentNode.removeChild(e);}},3000);',
    j = ' type="text/javascript"',
    t = 0,
    D = document,
    n = ar_cn;
  L =
    '' +
    ('https:' == document.location.protocol ? 'https:' : 'http:') +
    '' +
    L +
    encodeURIComponent(D.referrer || 'unknown') +
    '&rnd=' +
    Math.round(Math.random() * 999999999);
  function _() {
    if (t++ < 100) {
      const F = D.getElementById('ar_container_' + n) as HTMLIFrameElement;
      if (F) {
        try {
          const d =
            F.contentDocument ||
            ((window as WindowWithAdriver).ActiveXObject &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.frames['ar_container_' + n].document);
          if (d) {
            d.write(
              '<sc' +
                'ript' +
                j +
                '>var ar_bnum=' +
                n +
                ';' +
                S +
                '</sc' +
                'ript><sc' +
                'ript' +
                j +
                ' src="' +
                L +
                `" onload="parent.adriverExtentionLoad?.(ar_adid,bid, '${container_id}')"><\/sc` +
                'ript>'
            );
            t = 0;
          } else setTimeout(_, 100);
        } catch (e) {
          try {
            F.src =
              "javascript:{document.write('<sc'+'ript" +
              j +
              '>var ar_bnum=' +
              n +
              '; document.domain="' +
              D.domain +
              '";' +
              S +
              "</sc'+'ript>');document.write('<sc'+'ript" +
              j +
              ' src="' +
              L +
              `" onload=\"parent.adriverExtentionLoad?.(ar_adid,bid, '${container_id}')\"><\/sc'+'ript>');}`;
            return;
          } catch (E) {}
        }
      } else setTimeout(_, 100);
    }
  }

  const c = D.querySelector(`#${container_id}`);
  if (c) {
    c.innerHTML = `<div style="visibility:hidden;height:0;left:-1000px;position:absolute;"><iframe id="ar_container_${ar_cn}" width=1 height=1 marginwidth=0 marginheight=0 scrolling=no frameborder=0></iframe></div><div id="ad_ph_${ar_cn}" style="display:none;"></div>`;
  }
  _();
};

export const callBanner = (
  container_id: string,
  sid: string,
  bn: string,
  arCn?: number
) => {
  const link = `//ad.adriver.ru/cgi-bin/erle.cgi?sid=${sid}&bn=${bn}&bt=43&pz=0&target=top&tail256=`;
  const initialize = async () => await init(link, container_id, arCn);
  initialize();
};
