import { usePubNubStore } from '@/store/usePubNubStore';
import { useEffect, useState } from 'react';
import { checkOnUnreadMessage } from '@/utils/pubnub/checkOnUnreadMessage';

export function useHasUnreadMessages() {
  const [lastMessagesMap] = usePubNubStore(state => [state.lastMessagesMap]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    setHasUnreadMessages(checkOnUnreadMessage(lastMessagesMap));
  }, [lastMessagesMap]);

  return hasUnreadMessages;
}
